import { Box, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useGet } from '../../API/request';
import StatisticsItem from '../../components/dashboard/StatisticsItem';
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableTitlePage } from '../../components/table-title-page/TableTitlePage';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import {
  Bookmark,
} from 'react-feather';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [loaded, setLoaded] = useState(false);
  const getU = useGet();
  const [statistics, setStatistics] = useState({
    allUsers: 0,
    allCompany: 0,
    allPlacements: 0,
  })


  const loadRooms = () => {
    let endpoint = `placements?limit=${1}&page=${1}`;
  
    getU(endpoint)
    .then(resp => {
      if (resp.status === 'success' && resp.data.total) {
        setStatistics(prev => ({ ...prev, allPlacements: resp.data.total }))
      } else {
        throw(resp.message)
      }
    })
    .catch(error => console.log(error))
    .finally(() => {
      setLoaded(false);
    })
  };

  const loadCompany = () => {
    let endpoint = `companies?page=${1}&limit=${1}`;
  
    getU(endpoint)
        .then((resp) => {
            if (resp.status === 'success' && resp.data.totalCount) {
               setStatistics(prev => ({ ...prev, allCompany: resp.data.totalCount }))
            } else {
              throw(resp.message)
            }
        })
        .catch((err) =>  console.log(err))
        .finally(() => {
          loadRooms();
        })
  };

  const loadUsers = () => {
    setLoaded(true);
    let endpoint = `admin/users?page=${1}&limit=${1}`;

    getU(endpoint)
        .then(async(resp) => {
            if (resp.status === 'success' && resp.data.totalCount) {
               setStatistics(prev => ({ ...prev, allUsers: resp.data.totalCount }))
            } else {
              throw(resp.message)
            }
        })
        .catch((err) =>  console.log(err))
        .finally(() => {
          loadCompany();
        })
};


  useEffect(() => {
    loadUsers();
  },[])


  if (loaded) {
    return <Loader/>;
  }


  return (
    <>
      <HelmetPage title='Статистика' />
      <TableTitlePage title='Статистика' />
      
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
          {statistics.allUsers > 0 &&
            <Grid item lg={3} sm={6} xl={4} xs={12}>
          <Link to={'/app/users'}>
              <StatisticsItem icon={PeopleIcon} count={statistics.allUsers} title={'Общее количество пользователей'} />
          </Link>
            </Grid>
          }
          {statistics.allCompany > 0 &&
            <Grid item lg={3} sm={6} xl={4} xs={12}>
          <Link to={'/app/companys'}>
              <StatisticsItem icon={Bookmark} count={statistics.allCompany} title={'Общее количество компаний'} />
          </Link>
            </Grid>
          }
          {statistics.allPlacements > 0 &&
            <Grid item lg={3} sm={6} xl={4} xs={12}>
          <Link to={'/app/rooms'}>
              <StatisticsItem icon={RoomPreferencesIcon} count={statistics.allPlacements} title={'Общее количество помещений'} />
          </Link>
            </Grid>
          }
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
