import {createSlice} from '@reduxjs/toolkit';
import {post} from "../../API/request";
import { extraReducers } from './extraReducers';

export const initialState = {
    isAutorization: false,
    loading: false,
    error: null,
    accessToken: '',
    refreshToken: '',
    tokenExpire: '',
    user: {},
    ava: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action) => {
            const { accessToken, refreshToken } = action.payload
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            state.isAutorization = true;
        },
        logout: (state) => {
            state.accessToken = '';
            state.refreshToken = '';
            state.user = {};
            state.isAutorization = false;
        },
        setUser: (state, action) => {
            state.user = action.payload || {};
        },
        setAva: (state, action) => {
            state.ava = action.payload
        },
    },
    extraReducers: extraReducers,
});

export const fetchLogin = (dispatch, {data, onSuccess = () => {}, onFailed = () => {},}) => {

    post('login', data)
    .then(({ data }) => {
        dispatch(setTokens({
            accessToken: data.data.tokens.accessToken,
            refreshToken: data.data.tokens.refreshToken,
        }));
        dispatch(setUser(data.data.user))
        onSuccess(data.data);
    }).catch((error) => {
        onFailed(error);
    });
};



export const {logout, setTokens, setRememberMe, setLoginData, setUser, setAva, } = authSlice.actions;

export const authReducer = authSlice.reducer;

