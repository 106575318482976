import { Container, Divider, FormControl, Grid, IconButton, List, ListItem, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import { usePost } from "../../API/request";



const Chat = ({ messages, callback, admin, reciever_id }) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('')

  const postU = usePost();

  const validate = () => {
    if (message.length === 0) {
      setError('Введите сообщение')
      return false;
    }
    if (message.length >= 255) {
      setError('Сообщение не должно превышать 255 символов')
      return false;
    }
    return true;
  }

  const handleSendMessage = () => {
    if (!validate()) return;

    const endpoint = 'messages'
    const payload = {
      'user_id': admin.id,
      message,
      reciever_id: Number(reciever_id),
    }

    postU(endpoint, payload)
    .then((resp) => {
      if (resp.status === 'success') {
        callback()
        setMessage('')
    }
    })
    .catch((err) => {
      console.log('error', 'Error server');
  })
  };

  const handleMessageChange = (event) => {
    if (error) {
      setError('')
    }
    setMessage(event.target.value)
  }


  return (
    <Fragment>
    <Container maxWidth='80%'>
    <Paper elevation={2}>
    <Box p={4} mt={1}>
    <Typography variant="h4" gutterBottom pb={2}>
       Сообщение
    </Typography>
    <Divider />
    <Grid container spacing={4} alignItems='center'>
    <Grid id='chat-window' xs={12}   item>
      <List id='chat-windows-messages'>
      {messages.map(message =>
        <ListItem key={message.id}  className={admin.id === message.user_id ? `chat-message-item chat-message-item-my` : 'chat-message-item'}>
    <p  className={admin.id === message.user_id ? `chat-message chat-message-my` : 'chat-message'} >{message.message}</p>
    </ListItem>
      )}
      </List>
    </Grid>

{error &&
<span className='chat-messages-error'>
        {error}
</span>
}

  <Grid columnGap={4} item display='flex' alignItems='center' justifyContent='center' width='100%'>
    <Grid xs={10} item>
    <FormControl fullWidth>
        <TextField
        onChange={handleMessageChange}
          value={message}
          label='Введите сообщение...'
          variant="outlined"
        />
      </FormControl>
    </Grid>

    <Grid item >
      <IconButton
      onClick={handleSendMessage}
      aria-label="send"
      color="primary"
      >
    <SendIcon />
      </IconButton>
    </Grid>
        </Grid>


    </Grid>
    </Box>
    </Paper>
    </Container>
    </Fragment>
  );
};

export  {Chat};