//@ts-ignorets
import { baseFetch } from "./main-fetch"

//@ts-ignorets
const fetchGet = ( url, params) => {
  return baseFetch({url,  method: 'GET', params: params ? params : null})
}
//@ts-ignorets
const fetchPost = ({ url, payload, params }) => {
  return baseFetch({url,  payload, method: 'POST', params: params ? params : null})
}
//@ts-ignorets
const fetchPatch = (url, payload, params) => {
  return baseFetch({url, payload, method: 'PATCH', params: params ? params : null})
}
//@ts-ignorets
const fetchDelete = (url, params) => {
  return baseFetch({url, method: 'DELETE', params: params ? params : null})
}
//@ts-ignorets
const fetchPut = ({url, payload, params}) => {
  return baseFetch({url, method: 'PUT', payload, params: params ? params : null})
}


export const FetchService = {
  post: fetchPost,
  get: fetchGet,
  delete: fetchDelete,
  patch: fetchPatch,
  put: fetchPut,
}