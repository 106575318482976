import { useEffect, useState } from 'react';
import { Loader } from '../../components/loader/Loader';
import { useGet, useDelete } from '../../API/request';
import { HelmetPage } from '../../components/helmet/HelmetPage';
//@ts-ignore
import UserListSkelet from '../../skeletons/UserListSkelet';
import { TableTitlePage } from '../../components/table-title-page/TableTitlePage';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

//@ts-ignore
import { useConfirm } from '../../components/Confirm/index';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';
import { FetchService } from '../../shared/api/fetch/service';
import { TableFooter, TablePagination } from '@mui/material';

type RequestModel = {
  company_name: string;
  email: string;
  fio: string;
  id: number;
  inn: string;
  phone: string;
};

type AllRequestsResponse = {
  status: 'success' | 'error';
  message: string | null;
  data: {
    requests: RequestModel[];
    limit: number;
    page: number;
    total: number;
  };
};

type CreateCompanyResponse = {
  status: 'success' | 'error';
  message: string | null;
  data: {
    message: string;
    result: {
      company: {
        id: number;
        name: string;
        inn: string;
        supervisor_id: number;
      };
      supervisor: {
        avatar: string | null;
        company_id: number;
        created_at: null;
        fio: string;
        phone: string;
        refreshToken: string;
        role: string;
        updated_at: null;
        username: string;
        id: number;
        email: string;
        password: string;
      };
    };
  };
};

const RegistrationDepartmentList = () => {
  const [registrations, setRegistration] = useState<RequestModel[]>([]);
  const [loading, setLoading] = useState(false);
  const { alert, showAlert } = useShowAlert();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const getU = useGet();
  const deleteU = useDelete();

  const confirm = useConfirm();

  const fetchAllRegistrationsMessages = () => {
    setLoading(true);
    const endpoint = `requests?limit=100&page=1`;

    getU(endpoint)
      .then((resp: AllRequestsResponse) => {
        if (resp.status === 'success') {
          const { requests, total } = resp.data;
          if (total) {
            setCount(total);
          }
          setRegistration(requests);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при загрузке заявок, попробуйте повторно загрузить'
          );
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка на сервере, попробуйте обновить страницу'
        );
      })
      .finally(() => {
        setLoading(false);
        // setIsLoaded(false)
      });
  };

  const handleChangePage = (_: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event: any) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const createCompany = async (id: number) => {
    const endpoint = `requests/${id}`;

    const response: CreateCompanyResponse = await FetchService.post({
      url: endpoint,
      payload: null,
      params: null
    });
    console.log(response)
    if (response.status === 'error') {
      showAlert('error', `Произошла ошибка: ${response.message}`);
    } else {
      fetchAllRegistrationsMessages();
      showAlert('success', 'Компания успешно создана');
    }
  };

  const removeDepartment = async (id: number) => {
    const url = `requests/${id}`;

    (deleteU(url) as Promise<any>)
      .then((response) => {
        if (response.status === 'success') {
          fetchAllRegistrationsMessages();
          showAlert('success', 'Заявка успешно удалена');
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error), showAlert('error', `Произошла ошибка: ${error}`)
        )
      );
  };

  const handleCreateCompany = (id: number) => {
    confirm({
      title: 'Создать',
      content: 'Вы уверены, что хотите создать компанию?',
      onConfirm: () => {
        createCompany(id);
      }
    });
  };

  const handleRemoveCompany = (id: number) => {
    confirm({
      title: 'Создать',
      content: 'Вы уверены, что хотите удалить заявку?',
      onConfirm: () => {
        removeDepartment(id);
      }
    });
  };

  useEffect(() => {
    fetchAllRegistrationsMessages();
  }, [page, limit]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Список заявок" />
      <TableTitlePage title="Список заявок" />
      <AlertInputsNotification alert={alert} />

      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {loading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}></Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>ID</TableCell>
                            <TableCell>Почта</TableCell>
                            <TableCell>Телефон</TableCell>
                            <TableCell>ФИО</TableCell>
                            <TableCell>Название компании</TableCell>
                            <TableCell>ИНН</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {registrations &&
                            registrations.map((message) => (
                              <TableRow hover key={message.id}>
                                <TableCell sx={{ width: 80 }}>
                                  {message?.id}
                                </TableCell>

                                <TableCell>{message?.email || '---'}</TableCell>
                                <TableCell>{message?.phone || '---'}</TableCell>
                                <TableCell>{message?.fio || '---'}</TableCell>
                                <TableCell>
                                  {message?.company_name || '---'}
                                </TableCell>
                                <TableCell>{message?.inn || '---'}</TableCell>

                                <TableCell>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                      justifyContent: 'flex-end'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gap: 1
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          handleCreateCompany(message.id)
                                        }
                                        color="primary"
                                        variant="contained"
                                      >
                                        Принять
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleRemoveCompany(message.id)
                                        }
                                        color="error"
                                        variant="contained"
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Количество строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export { RegistrationDepartmentList };
