import React, { useState, useEffect } from 'react';
import { Box, Container, Card, CardContent, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from '../../components/loader/Loader';
import { InfoFieldItem } from '../../components/info-field-item/InfoFieldItem';
import { useGetCompanyWorkers } from '../../shared/hooks/getWorkers';
import WorkersList from '../../components/workers-list/WorckersList';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { Button } from '@mui/material';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { apiDomen }  from '../../API/environment'

const CompanyInfo = () => {
  const getU = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [currentCompany, setCurrentCompany] = useState({});
  const { workers, fetchWorkers, loadingWorkers } = useGetCompanyWorkers();

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCurrentCompany(resp.data.company);
          fetchWorkers(id);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded || loadingWorkers) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Сотрудники" />
      {/* <NavigateHeader
        title="Компании"
        breadCrumbs={{ firstText: 'Компании', linkText: 'Информация' }}
      /> */}

      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Link to={`/app/companys`}>
            <Button startIcon={<ChevronLeft />}>Назад</Button>
          </Link>
          <h2 className="title-header">Компании</h2>
        </Box>
        <ul className="headerList">
          <Link to={`/app/companys`}>
            <li>Компании</li>
          </Link>
          <li>/</li>
          <li style={{ color: 'black' }}>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
            <Box sx={{ marginLeft: 2 }}>
              <Link to={`/app/companys/protocols/${id}`}>
                <Button color="primary" variant="contained">
                  Протоколы компании
                </Button>
              </Link>
            </Box>
          </Box>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      variant="circular"
                      src={`${process.env.REACT_APP_API_URL}/uploads/avatars/${currentCompany.avatar}`}
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <InfoFieldItem
                      label="ID Компании"
                      item={currentCompany?.id}
                    />
                    <InfoFieldItem
                      label="Название"
                      item={currentCompany?.name}
                    />
                    <InfoFieldItem label="INN" item={currentCompany?.inn} />
                    <InfoFieldItem
                      label="ID Руководителя"
                      item={currentCompany?.supervisor_id}
                    />
                    {apiDomen &&
                    <InfoFieldItem
                    target='_blank'
                      label="Ссылка"
                      href={`${apiDomen}/?inn=${currentCompany?.inn}`}
                      item={`${apiDomen}/?inn=${currentCompany?.inn}`}
                    />
                    }
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>

      <WorkersList
        workers={workers}
        isDataLoading={isLoaded || loadingWorkers}
      />
    </>
  );
};

export default CompanyInfo;
