import axios from 'axios';
import { AxiosConfig } from './config';
import {
  interceprorsRequest,
  interceptorsResponseConfig,
  interceptorsResponseError
} from './interceptors';

export const publicHttp = axios.create(AxiosConfig);

publicHttp.interceptors.request.use((config) => interceprorsRequest(config));

publicHttp.interceptors.response.use(
  (config) => interceptorsResponseConfig(config),
  (error) => interceptorsResponseError(error)
);
