import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGet } from '../../API/request';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { AddUserCompany } from '../../components/company/add-user-company/AddUserCompany';
import { EditCompanyInfo } from '../../components/company/edit-company-info/EditCompanyInfo';
import { NavigateHeader } from '../../components/navigate-header/NavigateHeader';
import { Loader } from '../../components/loader/Loader';
import { CompanyEditQr } from '../../components/company-edit-qr/CompanyEditQr';
import { EditCompanyPreview } from '../../components/company/edit-company-preview/EditCompanyPreview';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';

const CompanyEdit = () => {
  const { id } = useParams();
  const getU = useGet();

  const [isLoaded, setIsLoaded] = useState(true);
  const [currentCompany, setCurrentCompany] = useState<{ supervisor_id: number; name: string; inn: string } | null>(null);
  const [uploadedImg, setUploadedImg] = useState<any | null>(null);

  const [supervisorList, setSupervosorList] = useState([]);
  const { alert, showAlert } = useShowAlert();

  const fetchGetAllSupervisors = async() => {
    setIsLoaded(true);
  
    const endpoint = 'users/all/supervisors';

    getU(endpoint)
      .then((resp: any) => {
        if (resp.status === 'success') {
          if (resp.data.supervisors.length > 0) {
            setSupervosorList(resp.data.supervisors)
          }
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке руководителей, попробуйте повторно загрузить'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies/${id}`)
      .then((resp: any) => {
        if (resp.status === 'success') {
          const { inn, name, supervisor_id, avatar } = resp.data.company;

          const preview = avatar
            ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${avatar}`
            : '/static/images/defphoto.jpg';

          setUploadedImg(preview)
          
          setCurrentCompany({
            supervisor_id: supervisor_id,
            name: name,
            inn: inn
          });

          fetchGetAllSupervisors();
        }
      })
      .catch(() => {})
      .finally(() => {
        // fetchQr();
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Редактировать компанию" />
      <NavigateHeader
        title="Компании"
        breadCrumbs={{ firstText: 'Компании', linkText: 'Редактировать' }}
      />
      <AlertInputsNotification alert={alert} />
      {id &&
      <EditCompanyPreview
      avatarUrl={uploadedImg}
      id={id}
      />
     }
      {currentCompany && id && (
        <EditCompanyInfo 
        id={id} 
        companyInfo={currentCompany}
        supervisorList={supervisorList} 
        />
      )}
      {id &&
      <>
      <AddUserCompany id={id} />
      <CompanyEditQr id={id}   />  
      </>
      }
    </>
  );
};

export { CompanyEdit };
