import { useState } from "react";
import { FetchService } from "../api/fetch/service";

interface UserModel {
  avatar?: string;
  company_id?: number;
  email: string;
  id: number;
  role: string;
  username: string;
  phone?: string;
  created_at?: string | null;
  updated_at?: string | null;
}

const useGetCompanyWorkers = () => {
  const [loadingWorkers, setLoadingWorkers] = useState(false);
  const [workers, setWorkers] = useState<UserModel[]>([])

  const filtersWorkers = (id: number) => {
    const newArrayWorker = workers.filter(worker => worker.id !== id) 
    setWorkers(newArrayWorker)
  }

  const fetchWorkers = async(companyId: string) => {
    setLoadingWorkers(true);
    const responseUsers: any = await FetchService.get(`companies/users/${companyId}`, null)
      if (responseUsers.status === 'success') {
        setWorkers(responseUsers.data.workers)
      } else {

      }
      setLoadingWorkers(false);
  }

  return { workers, filtersWorkers, fetchWorkers,  loadingWorkers };
};

export  { useGetCompanyWorkers };