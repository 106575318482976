import {Link as RouterLink, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    IconButton,
    Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../Logo/Logo';
import { useDispatch } from 'react-redux';
import { fetchAuthLogout } from '../../redux/slices/thunk'

const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {
    const dispath = useDispatch();
    const navigate = useNavigate();

    
    const onLogOut = () => {
        dispath(fetchAuthLogout());
        navigate('/login', {replace: true});
    };
    

    return (
        <AppBar
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
                <Box sx={{flexGrow: 1}}/>
                <IconButton color="inherit" size="large" onClick={onLogOut}>
                    <InputIcon/>
                </IconButton>
                <Box sx={{display: {xs: 'block', lg: 'none'}}}>
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
