import React, { useState, useEffect } from 'react';
import {
  Box,
  Avatar,
  Container,
  Card,
  CardContent
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from '../../components/loader/Loader';
import { InfoFieldItem } from '../../components/info-field-item/InfoFieldItem';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { NavigateHeader } from '../../components/navigate-header/NavigateHeader';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';


const UserInfo = () => {
  const getU = useGet();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [user, setUser] = useState({});
  const { alert, showAlert } = useShowAlert();
  const [currentCompany, setCurrentCompany] = useState(null);

  const fetchCompanyInfo = (id) => {
getU(`companies/${id}`)
.then((resp) => {
  if (resp.status === 'success') {
    setCurrentCompany(resp.data.company);
  } else {
    throw(resp.message)
  }
})
.catch((error) => {
  showAlert('error', `Ошибка сервера: ${error}`);
})
.finally(() => {
  setIsLoaded(false);
}); 
  }


  const fetchGetUserInfo = async () => {
    setIsLoaded(true);
    getU(`admin/users/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setUser(resp.data.user);
          if (resp.data.user.company_id) {
            fetchCompanyInfo(resp.data.user.company_id)
          }
        }
      })
      .catch(() => {
        showAlert('error', 'Ошибка сервера');
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      }); 
  };


  useEffect(() => {
    fetchGetUserInfo()
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title='Пользователь' />
     
      <NavigateHeader
        title="Сотрудники"
        breadCrumbs={{ firstText: 'Сотрудники', linkText: 'Информация' }}
      />
       <AlertInputsNotification alert={alert} />
      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="avatar-block">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/uploads/avatars/${user.avatar}`}
                      className="avatar"
                    />
                  </div>
                  <div className="info-block">
                    <InfoFieldItem
                      widthLabel={100}
                      label="ID:"
                      item={user?.id}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="ФИО:"
                      item={user?.fio}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Логин:"
                      item={user?.username}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Почта:"
                      item={user?.email}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Роль:"
                      item={user?.role}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Телефон:"
                      item={user?.phone}
                    />
                    <InfoFieldItem
                      widthLabel={100}
                      label="Компания:"
                      item={currentCompany?.name ? currentCompany.name : '---'}
                    />
                  </div>
                </div>

                
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default UserInfo;
