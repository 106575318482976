import { useRef, useState } from 'react';
import { Box, Container, Card } from '@material-ui/core';
import {  useGet, usePost, usePut } from '../../API/request';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { Button, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';
import { API_URL } from '../../shared/api/axios/config';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';


const SettingsExampl = () => {
  const postU = usePost();
  const putU = usePut();
  const getU = useGet();
  const [isLoaded, setIsLoaded] = useState(false);
  const fileRef = useRef();
  const { alert, showAlert } = useShowAlert();

  const handleSavePDF = () => {
    fileRef.current.click()
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    
    if (!selectedFile) return;

    setIsLoaded(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    const url = `${API_URL}/uploads/doc`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
  
      const json = await response.json()
  
      if (json.status === 'success') {
        showAlert('success', 'Файл добавлен')
      } else {
        throw(json.message)
      } 
      setIsLoaded(false);
    } catch (error) {
      showAlert('error', error);
    } 
  };

  const getCurrentKey = () => {
    return new Promise((res, rej) => {

      const url = 'configuration/keys/name/infoFilePDF';
      getU(url)
      .then(resp => {
        if (resp.status === 'success') {
        res(resp.data.configurationKey.key);
      } else {
        rej(resp.message)
      }
    })
    .catch(error => showAlert('error', `Ошибка: ${error}`))
  })
  }


  const handleDownload =  async() => {
    const key = await getCurrentKey();
    
    if (!key) {
      return showAlert('error', `Ошибка получении ключа`)
    };

    const endUrl = 'uploads/docs/pdf';
    
    try {
      const url = `${API_URL}/${endUrl}/${key}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
          throw("Не удалось загрузить файл")
      }
  
      const blobResult = await response.blob();
      const urlObject = URL.createObjectURL(blobResult);
      const link = document.createElement("a");
      link.addEventListener("", (e) => console.log(e));
      link.href = urlObject;
      link.download = `INFORMATION_ABOUT_THE_SYSTEMS.pdf`;
      link.click();
      URL.revokeObjectURL(urlObject);
    } catch (error) {
        showAlert('error', error);
    }
  }



  return (
    <>
      <HelmetPage title="Общие настройки" />
      <Box className="headerWrapper">
        <Box className="headerTitle">Общие настройки</Box>
      </Box>

      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '90%', py: 3 }}
      >
        <Container maxWidth={false}>
          <>
          <Box sx={{ pb: 3 }}>
          <AlertInputsNotification alert={alert} />
          </Box>
            <Box >
              <Card variant="outlined" sx={{maxWidth: 500, minWidth: 500, p: 3, display: 'inline-block', pl: 5, pr: 5}}>
               
                <CardContent sx={{pb: 0, pt: 1}}>
                  <Typography variant="h5" gutterBottom >
                  PDF-файл для главной страницы
                  </Typography>
                <Divider  />
                </CardContent>
                <Box sx={{pb: 3, pt: 0, width: '100%'}}></Box>
                <CardActions sx={{ justifyContent: 'space-between', width: '100%', columnGap: 0}}>
                <input  onChange={handleFileChange} type="file" hidden ref={fileRef} accept=".pdf" />
                  <Button
                    disabled={isLoaded}
                    variant="contained"
                    color="primary"
                    onClick={handleSavePDF}
                  >
                  <FileUploadIcon/>
                    ЗАГРУЗИТЬ PDF
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                  >
                  <DownloadIcon />
                    СКАЧАТЬ
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </>
        </Container>
      </Box>
    </>
  );
};

export default SettingsExampl;
