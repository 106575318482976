import { Box, Alert } from '@material-ui/core';

type Props = {
  alert: { type: 'error' | 'success'; isVisible: boolean; txt: string };
};

const AlertInputsNotification = ({ alert }: Props) => {

  if (!alert.isVisible) {
    return null;
  }

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Alert
          severity={alert.type}
          style={{ display: alert.isVisible ? 'flex' : 'none' }}
        >
          <span>{alert.txt}</span>
        </Alert>
      </Box>
    </>
  );
};

export { AlertInputsNotification };
