import { Box } from "@material-ui/core";

type Props = {
  title: string;
}

const TableTitlePage = ({ title }: Props) => {

  return (
    <Box className="headerWrapper">
    <Box className="headerTitle">
      {title}
    </Box>
  </Box>
  );
};

export  {TableTitlePage};