import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@material-ui/core';
import { Button } from '@mui/material';
import type { ReactNode } from 'react';

type Props = {
  seleckList: string[];
  value: string;
  onChange: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined;
  label: string;
  name: string;
  width?: string | number;
  allowClear?: boolean; 
}

const DropDownSelect = ({ allowClear, seleckList, value, onChange, label, name, width }: Props) => {

  if (!seleckList) {
    return null;
  }

  


  return (
    <FormControl fullWidth sx={{ mt: 2, mb: 1, width: width }}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={label}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {seleckList?.map((item, index) => (
          <MenuItem key={`${item}${index}`} value={item}>{item}</MenuItem>
        ))}
      {allowClear && value && (
        <Button 
        style={{position: 'relative', left: 10}}
        type="button" variant='text' value='' 
        >Отмена</Button>
      )}
      </Select>
    </FormControl>
  );
};

export { DropDownSelect };
