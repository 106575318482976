import { useLayoutEffect, useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from '@material-ui/core';
import { AlertInputsNotification } from '../../alert-inputs-notification/AlertInputsNotification';
import { useShowAlert } from '../../../shared/hooks/useShowAlert';
import { usePut } from '../../../API/request';

type Props = {
  avatarUrl: string | null;
  id: string;
}

const UserEditAvatar = ({ avatarUrl, id }: Props) => {
  const putU = usePut();
  const { alert, showAlert } = useShowAlert();
  const [values, setValues] = useState({
    img: '',
  });
  const [nothingChanged, setNothingChanged] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [uploadedImg, setUploadedImg] = useState(() => avatarUrl ? avatarUrl : '/static/images/defphoto.jpg');

  const avaUploaded = (event: any) => { 
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      img: event.target.files[0]
    });
  };

  useLayoutEffect(() => {
    if (avatarUrl) {
      setUploadedImg(avatarUrl);
    }
  },[avatarUrl])

  const submitAvatar = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('avatar', values.img);

    const endpoint = `admin/users/avatar/${id}`

    putU(endpoint, data)
      .then((resp: any) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные были успешно обновлены');
          setNothingChanged(true)
        } else {
          showAlert('error', 'Error');
        }
      })
      .catch(() => {
        showAlert('error', 'Ошибка сервера');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
        <AlertInputsNotification alert={alert} />
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование изображение" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input 
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={'1'}
                        onChange={(event) => avaUploaded(event)}
                      />
                      <label htmlFor={'1'}>
                        <img src={uploadedImg} className="itemImg" />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAvatar}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
  );
};

export { UserEditAvatar };