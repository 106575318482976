import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableFooter, TablePagination } from '@mui/material';



const RoomList = () => {
  const getU = useGet();
  const [isLoaded, setIsLoaded] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
};

  const loadRooms = () => {
    setIsLoaded(true);
    let endpoint = `placements?limit=${limit}&page=${page +  1}`;

    getU(endpoint)
    .then(resp => {
      if (resp.status === 'success') {
        const { placements, currentCount, total } = resp.data;
        setRooms(placements);
        if (currentCount) {
          setCount(currentCount)
        }
      }
    })
    .catch(error => console.log(error))
    .finally(() => setIsLoaded(false))
  };

  useEffect(() => {
    loadRooms();
  },[page, limit])

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title='Помещения' />
      <Box className="headerWrapper">
        <Box className="headerTitle">
          Помещения
        </Box>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py:  3 }}>
        <Container maxWidth={false}>
          {isLoaded ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ pt:  3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth:  1400 }}>
              
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width:  80 }}>
                              Id
                            </TableCell>
                            <TableCell>
                            Площадь
                            </TableCell>
                            <TableCell>
                            Назначение помещения
                            </TableCell>
                            <TableCell>
                            Специальное или приспособленное
                            </TableCell>
                            <TableCell>
                            Наличие специального оборудования
                            </TableCell>
                            <TableCell>
                            Место нахождения или иная уникальная идентификация
                            </TableCell>
                            <TableCell>
                            Право собственности или иное законное основание
                            </TableCell>

                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rooms && rooms.map((room) => (
                            <TableRow hover key={room.id}>
                              <TableCell sx={{ width:  80 }}>
                                {room?.id}
                              </TableCell>
                              <TableCell sx={{ width:  80 }}>
                                {room?.square || '---'}
                              </TableCell>
                              <TableCell>
                                {room?.direction || '---'}
                              </TableCell>
                              <TableCell sx={{ width:  140 }}>
                                {room?.type_laboratory || '---'}
                              </TableCell>
                              <TableCell>
                                {room?.special_equipment || '---'}
                              </TableCell>
                              <TableCell>
                                {room?.location || '---'}
                              </TableCell>
                              <TableCell>
                                {room?.right_to_use || '---'}
                              </TableCell>
                              <TableCell>
                         <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                          <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
                              <Link to={`/app/rooms/info/${room.id}`}>
                                  <Button color="primary"
                                          variant="contained">
                                      Инфо.
                                  </Button>
                              </Link>
                          </Box>
                      </Box>
                  </TableCell>
                      
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Количество строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default RoomList;