import React, {useState, useEffect} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { usePost} from '../../API/request';
import '../../styles/All.css'
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableFooter, TablePagination, TextField } from '@mui/material';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const ProtocolList = () => {
    const postU = usePost();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const [isLoaded, setIsLoaded] = useState(true);
    const [protocols, setProtocols] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);


    const statusList = ['Проект', 'Действует','Аннулирован','Изменен']
   
    const [queryParams, setQueryParams] = useState({
        date_start: searchParams.get("date_start") || '',
        date_end: searchParams.get("date_end") || '',
        status: searchParams.get("status") || '',
        evidence: searchParams.get("search") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };


    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.date_start !== '') {
            params.date_start = queryParams.date_start
        }
        if (queryParams.date_end !== '') {
            params.date_end = queryParams.date_end
        }
        if (queryParams.status !== '') {
            params.status = queryParams.status
        }
        if (queryParams.evidence !== '') {
            params.evidence = queryParams.evidence
        }

        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadCompany = () => {
        setIsLoaded(true)
 
        let endpoint = `companies/${id}/protocols?limit=10?page=1`;

        const filters = {};

        for (let field in queryParams) {
            if (queryParams[field]) {
                filters[field] = queryParams[field]
            }
        }

        const payload = { filters }

        postU(endpoint, payload)
            .then((resp) => {
                if (resp.status === 'success') {
                    const { total,  page, limit, protocols } = resp.data

                    setProtocols(protocols);
                    if (total) {
                         setCount(total); 
                    }
                  
                } else {
                    throw(resp.message)
                }
            })
            .catch((err) =>  console.log(err))
            .finally(() => (
                setIsLoaded(false)
            ));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    useEffect(() => {
        if (queryParams.date_start === '') {
            searchParams.delete("date_start")
            setSearchParams(searchParams);
        }
        if (queryParams.date_end === '') {
            searchParams.delete("date_end")
            setSearchParams(searchParams);
        }
        if (queryParams.status === '') {
            searchParams.delete("status")
            setSearchParams(searchParams);
        }
        if (queryParams.evidence === '') {
            searchParams.delete("evidence")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadCompany();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return  <Loader/>;
    }

    return (
        <>
            <HelmetPage title='Компании' />
            <Box className="headerWrapper">
                <Box className="headerTitle">
                Протоколы
                </Box>
            </Box>
        
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                <Link  to={`/app/company/${id}`}>

            <Box className="headerTitle">
        <Button startIcon={<ChevronLeft />}>
          Назад
        </Button> 
        </Box> 
                </Link>
                    {
                        isLoaded ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Box sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr 1fr 1fr 1fr 120px',
                                                    columnGap: 1,
                                                    mx: 2,
                                                    mb: 1,
                                                    pt: 1,
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="date_start"
                                                        onChange={handleChangeQueryParams}
                                                        type="date"
                                                        value={queryParams.date_start}
                                                        variant="outlined"
                                                    />
                                                     <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="date_end"
                                                        onChange={handleChangeQueryParams}
                                                        type="date"
                                                        value={queryParams.date_end}
                                                        variant="outlined"
                                                    />

                                                    <div>
                                                    <DropDownSelect
                                                        label='Статус'
                                                        name='status'
                                                        onChange={(e) => handleChangeQueryParams(e)}
                                                        seleckList={statusList}
                                                        value={queryParams.status}
                                                        allowClear
                                                    />
                                                    </div>
                                                    <TextField
                                                        fullWidth
                                                        label="Поиск по основанию"
                                                        margin="normal"
                                                        name="evidence"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.evidence}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        style={{
                                                    
                                                        }}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                              

                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Статус
                                                            </TableCell>
                                                            <TableCell>
                                                                Основание
                                                            </TableCell>
                                                            <TableCell>
                                                             Номер протокола
                                                            </TableCell>
                                                            <TableCell>
                                                             Объект испытаний
                                                            </TableCell>
                                                            <TableCell>
                                                             Заказчик испытаний
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {protocols && protocols.map((protocol) => (
                                                            <TableRow hover key={protocol.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {protocol?.id}
                                                                </TableCell>

                                                                <TableCell style={{position: 'realitive'}} >
                                                                    {protocol?.status || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {protocol?.evidence || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {protocol?.number || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {protocol?.object_test || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {protocol?.customer_test || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                                                                        <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
                                                                            <Link to={`/app/companys/protocols/info/${protocol.id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Инфо.
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Количество строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default ProtocolList;
