import { useState } from "react";
import { useGet } from "../../API/request";
import { Chat } from "../../components/Chat/Chat";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import {useDispatch} from "react-redux";
import { NavigateHeader } from "../../components/navigate-header/NavigateHeader";

const Communication = () => {
  const getU = useGet();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [messages, setMessages] = useState([])
  const [admin, setAdmin] = useState(null)
  const { id } = useParams();
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
});

  const fetchAdmin = () => {
      getU(`users/current`)
          .then((resp) => {
              if (resp.status === 'success') {
                  let userData = resp.data.user;
                  setAdmin(userData)
                  dispatch(setUser(resp.data.user))
              }
          })
          .catch((err) => {
              showAlertInfo('error', 'Данные не были загружены');
          });
  
  }

  const showAlertInfo = (type, text) => {
    setAlertInfo({
        txt: text,
        type,
        isVisible: true
    });

    setTimeout(() => {
        setAlertInfo({
            txt: text,
            type,
            isVisible: false
        });

        setSubmitDisabled(false);
    }, 2500);
};


  const fethAllMessages = async() => {
    setIsDataLoading(true)
    const endpoint = `messages/dialog/users/${id}?limit=100`

    getU(endpoint)
    .then((resp) => {
      if (resp.status === 'success') {
        const { messages, limit, page } = resp.data
        setMessages(resp.data.messages.data)

        if (messages.length !== 0) {  
          fetchAdmin()
        }
         
      }
    })
    .catch((err) => {
      setMessages([]);
  })
  .finally(() => {
    setIsDataLoading(false)
  });
  }

  useEffect(() => {
    fethAllMessages()

  },[])

  useEffect(() => {
    const timer = setInterval(() => fethAllMessages(), 10000)
    return () => {
      clearInterval(timer)
    }
  },[])

  return (
    <>
    <NavigateHeader />
    {messages.length > 0 && admin &&
      <Chat reciever_id={id} messages={messages} callback={fethAllMessages} admin={admin} />
    }
    </>
  );
};

export { Communication };