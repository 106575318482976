import { publicHttp } from "./public-http"


const axiosGet = (path, config) => {
  return publicHttp.get(`api/${path}`, config)
}

const axiosPost = (path, payload, config) => {
    return publicHttp.post(`api/${path}`, payload, config)
}

const axiosPut = (path, payload = {}, config) => {
  return publicHttp.put(`api/${path}`, payload, config)
}

const axiosDelete = (path, payload = {}, config) => {
  return publicHttp.delete(`api/${path}`, payload, config)
}

const axiosPatch = (path, payload = {}) => {
  return publicHttp.patch(`api/${path}`, payload)
}


export const AxiosService = {
  get: axiosGet,
  post: axiosPost,
  put: axiosPut,
  delete: axiosDelete,
  path: axiosPatch
}