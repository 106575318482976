import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import { usePost} from "../../API/request";
import React, { useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';
import { useShowAlert } from '../../shared/hooks/useShowAlert';


const ConfigurationKeysAdd = () => {

    const navigate = useNavigate();
    const postU = usePost();

    const [values, setValues] = useState({
        name: '',
        key: '',
    });
    const [errors, setErrors] = useState({
        name: false,
        key: false,
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const { alert, showAlert } = useShowAlert();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const resetDisabled = () => setSubmitDisabled(() => false);

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name === '') {
            validComplete = false;
            formErrors.role = false;
            showAlert('error', "Поле имя не должно быть пустым", resetDisabled)
        }

        setErrors(formErrors);
        return validComplete;
    };



    const clearForm = () => {
        setValues({
            key: '',
            name: '',
        });
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const data = {
                name: values.name,
                key: values.key,
            };

            console.log(data)

            postU('configuration/keys', data)
                .then((resp) => {
                    console.log(resp)
                    if (resp.status === 'success') {
                        showAlert('success', 'Добавлен ключ', resetDisabled);
                        clearForm();
                    } else {
                       throw(resp.message)

                    }
                })
                .catch((err) => {
                    showAlert('error', `Ошибка: ${err}`, resetDisabled);
                    setIsShowLoader(false);
                    setSubmitDisabled(false);
                })
                .finally(()=>{

                });
        }
    };

    return (
        <>
            <HelmetPage title='Создайте новый конфигурационный ключ' />
            <Box sx={{pt: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Создайте новый конфигурационный ключ"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Ключ"
                                        margin="normal"
                                        name="key"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.key}
                                        variant="outlined"
                                        error={errors.key}
                                    />
                                  <AlertInputsNotification alert={alert} />
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ConfigurationKeysAdd;
