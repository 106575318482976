import { useState } from "react";


type ShowAlertValues = {
  txt: string;
  isVisible: boolean;
  type: 'error' | 'success';
}

const useShowAlert = () => {

  const [alert, setAlert] = useState<ShowAlertValues>({
    txt: '',
    isVisible: false,
    type: 'error'
});

  const showAlert = (type: 'error' | 'success', text: string, callback?: () => void) => {
    setAlert({
        txt: text,
        type,
        isVisible: true
    });

    setTimeout(() => {
        setAlert({
            txt: text,
            type,
            isVisible: false
        });
        if (callback) {
          callback();
        }
    }, 1400);
};


  return { showAlert, alert };
};

export  { useShowAlert };