import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import { useShowAlert } from '../../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../alert-inputs-notification/AlertInputsNotification';
import { usePut } from '../../../API/request';
import { DropDownSelect } from '../../drop-down-select/DropDownSelect';

type Props = {
  id: string;
  userInfo: {
    email: string;
    username: string;
    phone: string;
    role: string;
    company: string;
    fio: string;
  };
  roles: string[];
};

const UserEditInfo = ({ id, userInfo, roles }: Props) => {
  const putU = usePut();
  const [values, setValues] = useState({
    email: userInfo.email ? userInfo.email : '',
    username: userInfo.username ? userInfo.username : '',
    phone: userInfo.phone && userInfo.phone !== '---' ? userInfo.phone : '',
    role: userInfo.role ? userInfo.role : '',
    company: userInfo.company ? userInfo.company : '',
    fio: userInfo.fio ? userInfo.fio : ''
  });
  const [errors, setErrors] = useState({
    email: false,
    phone: false,
    username: false,
    role: false,
    fio: false
  });
  const [nothingChanged, setNothingChanged] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { alert, showAlert } = useShowAlert();

  const handleChange = (event: { target: { name: any; value: any } }) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.fio === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле ФИО не должно быть пустым');
    }

    if (values.phone.length > 0 && values.phone.length < 10) {
      validComplete = false;
      formErrors.phone = false;
      showAlert('error', 'Телефон не должен содержать менее 10 символов');
    }

    if (values.role === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле роль не должно быть пустым');
    }

    if (values.email === '') {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Поле электронной почты не должно быть пустым');
    }
    if (values.username === '') {
      validComplete = false;
      formErrors.username = false;
      showAlert('error', 'Поле логин не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    if (validateInfo()) {
      setSubmitDisabled(true);

      const payload: Record<string, string | null> = {
        username: values.username,
        email: values.email,
        role: values.role,
        fio: values.fio
      };

      if (values.phone.length > 0) {
        payload.phone = values.phone;
      }

      if (values.phone.length === 0) {
        payload.phone = '---';
      }

      const endpoint = `admin/users/${id}`;

      putU(endpoint, payload)
        .then((resp: any) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные были успешно обновлены');
          } else {
            throw resp.message;
          }
        })
        .catch((error: any) => {
          showAlert('error', `Ошибка сервера: ${error}`);
        })
        .finally(() => {
          setNothingChanged(true);
          setSubmitDisabled(false);
        });
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
      <AlertInputsNotification alert={alert} />
      <Container maxWidth={false}>
        <Box
          sx={{
            paddingBottom: 1
          }}
        >
          <form>
            <Card>
              <CardHeader title="Редактирование пользователя" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label="ФИО"
                  margin="normal"
                  name="fio"
                  onChange={handleChange}
                  type="text"
                  value={values.fio}
                  variant="outlined"
                  error={errors.fio}
                />
                <TextField
                  fullWidth
                  label="Логин"
                  margin="normal"
                  name="username"
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                  error={errors.username}
                />
                <TextField
                  fullWidth
                  label="Почта"
                  margin="normal"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                  error={errors.email}
                />
                <TextField
                  fullWidth
                  label="Телефон"
                  margin="normal"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={values.phone}
                  variant="outlined"
                  error={errors.phone ? true : false}
                />
                <DropDownSelect
                  label="Роль"
                  name="role"
                  onChange={handleChange}
                  seleckList={roles}
                  value={values.role}
                />
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitInfo}
                  disabled={submitDisabled}
                >
                  Сохранить
                </Button>
              </Box>
            </Card>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export { UserEditInfo };
