import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Input,
  TextField,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useGet, usePut } from '../../API/request';
import { setUser } from '../../redux/slices/auth';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';


const Account = () => {
  const dispatch = useDispatch();
  const getU = useGet();
  const putU = usePut();

  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [userName, setUserName] = useState('');
  const { alert: alertInfo, showAlert: showAlertInfo } = useShowAlert();
  const { alert: alertPassword, showAlert: showAlertPassword } = useShowAlert();

  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    confirm: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    confirm: false
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);

  const uploadNewAvatar = () => {
    if (uploadedAvatar !== '') {
      let data = new FormData();
      data.append('avatar', uploadedAvatar);

      putU(`users/current/avatar`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            loadUserData();
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  };

  const avaUploaded = (event) => {
    setUploadedAvatar(event.target.files[0]);
  };

  const loadUserData = () => {
    getU(`users/current`)
      .then((resp) => {
        if (resp.status === 'success') {
          let userData = resp.data.user;
          setUserName(resp.data.user.username);
          setUserAvatar(
            `${process.env.REACT_APP_API_URL}/uploads/avatars/${resp.data.user.avatar}`
          );
          dispatch(setUser(resp.data.user));

          let vals = {
            name: userData.username || '',
            email: userData.email || ''
          };
          setValues(vals);
        }
      })
      .catch((err) => {
        setUserName('Admin');
        showAlertInfo('error', 'Данные не были загружены');
      });
  };

  const handleChange = (event) => {
    if (nothingChanged === true) {
      setNothingChanged(false);
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const submitDisabledFalse = () => setSubmitDisabled(false);


  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name.trim() === '') {
      validComplete = false;
      formErrors.firstName = true;
      showAlertInfo('error', 'Поле имени пользователя не должно быть пустым', submitDisabledFalse);
    }

    const reg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (values.email.trim() === '') {
      validComplete = false;
      formErrors.password = true;
      showAlertInfo('error', 'Поле почта не должно быть пустым', submitDisabledFalse);
    } else if (values.email.match(reg)) {
      showAlertInfo('error', 'Вы передели в поле почта не корректные данные');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const validatePassword = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.password) {
      if (!values.password) {
        validComplete = false;
        formErrors.password = true;
        showAlertPassword('error', 'Поле пароля не должно быть пустым', submitDisabledFalse);
      } else if (values.password.length < 8) {
        validComplete = false;
        formErrors.password = true;
        showAlertPassword('error', 'Пароль должен содержать более 8 символов', submitDisabledFalse);
      }

      if (values.password !== values.confirm) {
        validComplete = false;
        formErrors.confirm = true;
        showAlertPassword('error', 'Пароли не совпадают', submitDisabledFalse);
      }
    }

    setErrors(formErrors);
    return validComplete;
  };

  const onSubmitInfo = async () => {
    setSubmitDisabled(true);

    if (nothingChanged) {
      showAlertInfo('error', 'Никаких изменений', submitDisabledFalse);
      return;
    }

    if (validateInfo()) {
      const payload = {
        username: values.name,
        email: values.email
      };

      putU(`users/current`, payload)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlertInfo('success', 'Вы успешно обновили данные', submitDisabledFalse);
            loadUserData();
          } else {
            showAlertInfo('error', resp.message);
          }
        })
        .catch((err) => {
          showAlertInfo('error', 'Ошибка сервера', submitDisabledFalse);
        });
    }
  };

  const onSubmitPassword = async () => {
    setSubmitDisabled(true);

    if (nothingChanged) {
      showAlertInfo('error', 'Нет изменений', submitDisabledFalse);
      return;
    }

    if (validatePassword()) {
      const payload = {
        password: values.password
      };

      putU(`admin/current/password`, payload)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlertPassword('success', 'Вы успешно обновили данные', submitDisabledFalse);
            setValues({ ...values, password: '', confirm: '' });
          }
        })
        .catch((err) => {
          showAlertPassword('error', 'Ошибка сервера', submitDisabledFalse);
        });
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    uploadNewAvatar();
  }, [uploadedAvatar]);

  return (
    <>
      <HelmetPage title="Профиль" />
      <Box className="headerWrapper">
        <Box className="headerTitle">Профиль</Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Avatar
                      src={userAvatar}
                      sx={{
                        height: 100,
                        width: 100
                      }}
                    />
                    <Typography color="textPrimary" gutterBottom variant="h3">
                      {userName}
                    </Typography>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <Box style={{ width: '100%' }}>
                    <Input
                      accept="xlsx/*"
                      type="file"
                      style={{ display: 'none' }}
                      id="userAvatarUpload"
                      onChange={avaUploaded}
                    />
                    <label htmlFor="userAvatarUpload">
                      <Button
                        variant="text"
                        style={{ marginTop: '7px' }}
                        fullWidth
                        component="span"
                      >
                        Загрузить аватар
                      </Button>
                    </label>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <form autoComplete="off" noValidate>
                <Card>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} matginTop={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Имя"
                          name="name"
                          onChange={handleChange}
                          required
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Почта"
                          name="email"
                          onChange={handleChange}
                          required
                          value={values.email}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <AlertInputsNotification alert={alertInfo} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={submitDisabled}
                      onClick={onSubmitInfo}
                    >
                      Обновление
                    </Button>
                  </Box>
                </Card>

                <Card sx={{ mt: 4 }}>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={3} matginTop={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Пароль"
                          name="password"
                          onChange={handleChange}
                          required
                          type="password"
                          value={values.password}
                          variant="outlined"
                          error={errors.password}
                          helperText={
                            errors.password &&
                            'Пароль не должен быть короче 8 символов'
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Подтвердите пароль"
                          name="confirm"
                          onChange={handleChange}
                          type="password"
                          required
                          value={values.confirm}
                          variant="outlined"
                          error={errors.confirm}
                          helperText={errors.confirm && 'Пароли разные'}
                        />
                      </Grid>
                    </Grid>
                    <AlertInputsNotification alert={alertPassword} />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={submitDisabled}
                      onClick={onSubmitPassword}
                    >
                      Обновление
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
