import { useLayoutEffect, useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import { usePut } from '../../../API/request';
import { useShowAlert } from '../../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../alert-inputs-notification/AlertInputsNotification';
import { DropDownSelect } from '../../drop-down-select/DropDownSelect';

type Props = {
  id: string;
  companyInfo: { supervisor_id: number; name: string; inn: string };
  supervisorList: { id: number; fio: string }[];
};

const EditCompanyInfo = ({ id, companyInfo, supervisorList }: Props) => {
  const putU = usePut();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);
  const [currentSupervosor, setCurrentSupervisor] = useState<{ id: number; fio: string } | null>(null);


  useLayoutEffect(() => {
    const currentSupervisorCompany = supervisorList.find(item => item.id === companyInfo.supervisor_id);

    if (currentSupervisorCompany) {
      setCurrentSupervisor(currentSupervisorCompany);
    }
  }, [supervisorList]);

  const [values, setValues] = useState({
    name: companyInfo.name,
    inn: companyInfo.inn
  });
  const [errors, setErrors] = useState({
    name: false,
    inn: false
  });
  //@ts-ignore
  const [initValue, setInitValue] = useState<{
    supervisor_id: number;
    name: string;
    inn: string;
  }>({
    supervisor_id: companyInfo.supervisor_id,
    name: companyInfo.name,
    inn: companyInfo.inn
  });
  const { alert, showAlert } = useShowAlert();

  const resetDisabled = () => setSubmitDisabled(() => false);

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name === '') {
      validComplete = false;
      formErrors.name = false;
      showAlert(
        'error',
        'Поле Название компании не должно быть пустым',
        resetDisabled
      );
    } else if (values.inn.length < 10) {
      validComplete = false;
      formErrors.inn = false;
      showAlert(
        'error',
        'ИНН не должен быть меньше 10 символов',
        resetDisabled
      );
    }

    setErrors(formErrors);
    return validComplete;
  };

  const changeSelect = (value: any) => {
    setNothingChanged(false);
    const current = value.target.value;
    const findSupervisor = supervisorList.find(item => item.fio === current)
    if (current && findSupervisor) {
      setCurrentSupervisor(findSupervisor)
    }
  }

  const handleUpdateCompany = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }
    if (!validate() || !currentSupervosor || supervisorList.length === 0) return;
 
    //@ts-ignore
    const supervisorId =  supervisorList.find(item => item.fio === currentSupervosor.fio).id;

    if (!supervisorId) {
      return showAlert('success', 'Не указан руководитель компании');
    }

    const endpoint = `companies/${id}`;

    const payload: { supervisor_id?: number; name?: string; inn?: string } = {};

    if (initValue.inn !== values.inn) {
      payload.inn = values.inn;
    }

    if (initValue.supervisor_id !== supervisorId) {
      payload.supervisor_id = supervisorId;
    }

    if (initValue.name !== values.name) {
      payload.name = values.name;
    }

    putU(endpoint, payload)
      .then((resp: any) => {
        if (resp.status === 'success') {
          showAlert('success', 'Успешное обновление компании', resetDisabled);
          const { inn, name, supervisor_id } = resp.data.company;

          setNothingChanged(true);
          setInitValue({
            inn,
            name,
            supervisor_id,
          });
          setValues({
            inn,
            name
          });
        } else {
          showAlert('error', resp.message, resetDisabled);
        }
      })
      .catch((e: any) => {
        console.log(e.message);
        showAlert(
          'error',
          'Ошибка при попытке записать изменения',
          resetDisabled
        );
      })
      .finally(() => {
        resetDisabled();
      });
  };

  const handleChange = (event: { target: { name: any; value: any } }) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
      <AlertInputsNotification alert={alert} />

      <Container maxWidth={false}>
        <Box
          sx={{
            paddingBottom: 1
          }}
        >
          <form>
            <Card>
              <CardHeader title="Изменить информацию о компании" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label="Название"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                  error={errors.name ? true : false}
                />
                <TextField
                  fullWidth
                  label="ИНН"
                  margin="normal"
                  name="inn"
                  onChange={handleChange}
                  type="number"
                  value={values.inn}
                  variant="outlined"
                  error={errors.inn ? true : false}
                />
             
                  <DropDownSelect
                  label='Руководитель'
                  name='supervisor_id'
                  onChange={(e) => changeSelect(e)}
                  seleckList={supervisorList.map(item => item.fio)}
                  value={currentSupervosor?.fio ?? ''}
                  />
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleUpdateCompany}
                  disabled={submitDisabled}
                >
                  Сохранить
                </Button>
              </Box>
            </Card>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export { EditCompanyInfo };
