import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
//@ts-ignore
import { usePost } from '../../../API/request';
import { useShowAlert } from '../../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../alert-inputs-notification/AlertInputsNotification';

type Props = {
  id: string;
};

const initValues = {
  userId: ''
};

const AddUserCompany = ({ id }: Props) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { alert, showAlert } = useShowAlert();
  const postU = usePost();
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState({
    userId: false
  });

  const validateAddCompany = () => {
    if (values.userId.length === 0) {
      showAlert('error', 'Поле ID сотрудника обязательно к заполнению');
      return false;
    }
    return true;
  };

  const handleChange = (event: { target: { name: any; value: any } }) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const resetInputs = () => {
    setValues(initValues);
  };

  const resetDisabled = () => setSubmitDisabled(() => false);

  const handleAddUserInCompany = async () => {
    if (!validateAddCompany()) return;

    const endpoint = 'users/add-company';

    const payload = {
      id: values.userId,
      company_id: id
    };

    postU(endpoint, payload)
      .then((resp: any) => {
        if (resp.status === 'success') {
          showAlert('success', 'Сотрудник успешно добавлен', resetDisabled);
          resetInputs();
        } else {
          showAlert(
            'error',
            'Не удалось добавить сотрудника в компанию',
            resetDisabled
          );
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Не удалось добавить сотрудника в компанию',
          resetDisabled
        );
      });
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
      <AlertInputsNotification alert={alert} />
      <Container maxWidth={false}>
        <Box
          sx={{
            paddingBottom: 1
          }}
        >
          <form>
            <Card>
              <CardHeader title="Добавить сотрудника в компанию" />
              <Divider />
              <CardContent sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  label="ID Сотрудника"
                  margin="normal"
                  name="userId"
                  onChange={handleChange}
                  type="number"
                  value={values.userId}
                  variant="outlined"
                  error={errors.userId}
                />
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddUserInCompany}
                  disabled={submitDisabled}
                >
                  Добавить
                </Button>
              </Box>
            </Card>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export { AddUserCompany };
