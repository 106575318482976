import { Box, Button } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';

type Props = {
  title?: string;
  breadCrumbs?: { firstText: string; linkText: string };
};

const NavigateHeader = ({ breadCrumbs, title }: Props) => {
  const navigate = useNavigate();

  return (
    <Box className="headerWrapper">
      <Box className="headerTitle">
        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
          Назад
        </Button>
        {title &&
        <h2 className="title-header">{title}</h2>
        }
      </Box> 
      {breadCrumbs &&
      <ul className="headerList">
        <li onClick={() => navigate(-1)}>{breadCrumbs.firstText}</li>
        <li>/</li>
        <li>{breadCrumbs.linkText}</li>
      </ul>
      }
    </Box>
  );
};

export { NavigateHeader };
