import { Helmet } from 'react-helmet';

const HelmetPage = ({ title }: { title: string }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export { HelmetPage };
