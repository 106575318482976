import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchAuthLogin, fetchAuthLogout } from "./thunk";


export const extraReducers = (
  builder: ActionReducerMapBuilder,
) => {
  builder
    .addCase(fetchAuthLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAuthLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload : null;
    })
    .addCase(fetchAuthLogin.fulfilled, (state, action) => {
      const { accessToken, refreshToken, user } = action.payload
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.user = user
      state.tokenExpire = new Date().toDateString()
      state.isAutorization = true
      
      state.loading = false;
      state.error = null;
    });

  builder
    .addCase(fetchAuthLogout.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAuthLogout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ? action.payload : null;
    })
    .addCase(fetchAuthLogout.fulfilled, (state) => {
      state.accessToken = ''
      state.refreshToken = ''
      state.user = {}
      state.tokenExpire = ''
      state.isAutorization = false
      
      state.loading = false;
      state.error = null;
    });
 
};