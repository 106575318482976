import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
} from '@material-ui/core';
import { useShowAlert } from '../../../shared/hooks/useShowAlert';
import { usePut } from '../../../API/request';
import { AlertInputsNotification } from '../../alert-inputs-notification/AlertInputsNotification';


const UserEditPassword = ({ id }: {id: string}) => {
  const putU = usePut();
  const [values, setValues] = useState({
    password: '',
    confirm: '',
  });
  const [errors, setErrors] = useState({
    password: false,
    confirm: false,
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { alert, showAlert } = useShowAlert();

  const handleChange = (event: { target: { name: any; value: any; }; }) => {

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const validatePassword = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.password.length === 0) {
      validComplete = false;
      formErrors.password = true;; 
    }

    if (values.password) {
      if (!values.password) {
        validComplete = false;
        formErrors.password = true;
        showAlert('error', 'Поле пароль не должно быть пустым');
      } else if (values.password.length < 8) {
        validComplete = false;
        formErrors.password = true;
        showAlert('error', 'Пароль должен содержать более 8 символов');
      }

      if (values.password !== values.confirm) {
        validComplete = false;
        formErrors.confirm = true;
        showAlert('error', 'Пароли не совпадают');
      }
    } else {
      validComplete = false;
        formErrors.password = true;
        showAlert('error', 'Поле пароль не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submitPassword = async () => {

    if (validatePassword()) {
      setSubmitDisabled(true);

      const payload = {
        password: values.password
      };

      const endpoint = `admin/users/password/${id}`;

      putU(endpoint, payload)
        .then((resp: any) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные были успешно обновлены');
            setValues({ ...values, password: '', confirm: '' });
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch(() => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
       <AlertInputsNotification alert={alert} />
    <Container maxWidth={false}>
      <Box>
        <form>
          <Card>
            <CardHeader title="Редактирование пароля" />
            <Divider />
            <CardContent sx={{ position: 'relative' }}>
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                label="Пароль"
                name="password"
                onChange={handleChange}
                required
                type="password"
                value={values.password}
                variant="outlined"
                error={errors.password}
                helperText={
                  errors.password &&
                  alert.txt
                }
              />
              <TextField
                fullWidth
                label="Повторный пароль"
                name="confirm"
                onChange={handleChange}
                type="password"
                required
                value={values.confirm}
                variant="outlined"
                error={errors.confirm}
                helperText={errors.confirm && 'Пароли  разные'}
              />
            </CardContent>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitPassword}
                disabled={submitDisabled}
              >
                Сохранить
              </Button>
            </Box>
          </Card>
        </form>
      </Box>
    </Container>
  </Box>
  );
};

export  { UserEditPassword };