import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGet } from '../../API/request';
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { NavigateHeader } from '../../components/navigate-header/NavigateHeader';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { UserEditInfo } from '../../components/user/user-edit-info/UserEditInfo';
import { UserEditPassword } from '../../components/user/user-edit-password/UserEditPassword';
import { UserEditAvatar } from '../../components/user/user-edit-avatar/UserEditAvatar';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';

import '../../styles/Avatar/style.css';
import { UserEditRemove } from '../../components/user/user-edit-remove/UserEditRemove';

const UserEdit = () => {
  const { id } = useParams();
  const getU = useGet();

  const [isLoaded, setIsLoaded] = useState(true);
  const { alert, showAlert } = useShowAlert();
  const [uploadedImg, setUploadedImg] = useState<any | null>(null);
  const [currentUser, setCurrentUser] = useState<any | null>(null)
  const [roles, setRoles] = useState([]);

 
  useEffect(() => {
    setIsLoaded(true);
    getU(`admin/users/${id}`)
      .then((resp: any) => {
        if (resp.status === 'success') {
          const data = {
            username: resp.data.user.username,
            email: resp.data.user.email,
            role: resp.data.user.role,
            phone: resp.data.user.phone,
            fio: resp.data.user.fio ? resp.data.user.fio : ''
          };

          const avatar = resp.data.user.avatar
            ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${resp.data.user.avatar}`
            : '/static/images/defphoto.jpg';

            setCurrentUser(data)
            setUploadedImg(avatar);
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке ролей, попробуйте повторно загрузить'
        );
      })
      .finally(() => {});
    getU(`roles`)
      .then((resp: any) => {
        if (resp.status === 'success') {
          setRoles(resp.data.roles);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Редактировать сотрудника" />
      <NavigateHeader
        title="Сотрудники"
        breadCrumbs={{ firstText: 'Сотрудники', linkText: 'Редактировать' }}
      />
       <AlertInputsNotification alert={alert} />
      {id &&  
      <UserEditAvatar 
      avatarUrl={uploadedImg}
      id={id}
      />
      }
      {currentUser && id && roles &&
      <UserEditInfo
      id={id}
      roles={roles}
      userInfo={currentUser}
      />
      }
      {id &&
      <UserEditPassword id={id}/>
      }
      {id &&
      <UserEditRemove id={id}/>
      }
    
    </>
  );
};

export { UserEdit };
